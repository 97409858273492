import Vue from 'vue';

const THEME = {
  WHITE: 'white',
  YELLOW: 'yellow',
  PEACH: 'peach',
  AZURE: 'azure',
  PINK: 'pink',
  PURPLE: 'purple',
  ENTERPRISE: 'enterprise',
};

const themes = {
  [THEME.WHITE]: 'grey-10',
  [THEME.YELLOW]: 'yellow-10',
  [THEME.PEACH]: 'peach-10',
  [THEME.AZURE]: 'azure-60',
  [THEME.PINK]: 'pink-50',
  [THEME.PURPLE]: 'purple-50',
  [THEME.ENTERPRISE]: 'forest-50',
};

const textThemes = {
  [THEME.WHITE]: 'azure-60',
  [THEME.YELLOW]: 'azure-60',
  [THEME.PEACH]: 'azure-60',
  [THEME.AZURE]: 'grey-10',
  [THEME.PINK]: 'grey-10',
  [THEME.PURPLE]: 'grey-10',
  [THEME.ENTERPRISE]: 'grey-10',
};

Vue.prototype.$gooseThemes = themes;

Vue.mixin({
  props: {
    theme: {
      type: String,
      validator: t => themes.hasOwnProperty(t.toLowerCase()),
      default: 'white',
    },
  },
  computed: {
    /**
     * This is the main color for a theme. It is typically used as the background color.
     * @return {String} name of the variable e.g. grey-10
     */
    themeColor() {
      return themes[this.theme.toLowerCase()];
    },
    textThemeColor() {
      return textThemes[this.theme.toLowerCase()];
    },
  },
});
